export const SET_BM = "setBM";
export const SET_BMS = "setBMs";

export const SET_BM_DAYS_OFF = "setBMDaysOff";
export const SET_BM_IMAGE = "setBmImage";
export const BM_RESET = "bmReset";
export const BM_RESET_STATE = "bmResetState";
export const BM_ADD_SERVICE = "bmAddService";
export const BM_REMOVE_SERVICE = "bmRemoveService";
export const BM_ADD_WEEKDAY = "bmAddWeekDay";
export const BM_REMOVE_WEEKDAY = "bmRemoveWeekDay";
