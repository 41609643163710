<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-card>
          <v-card-title>
            <v-icon>mdi-hair-dryer-outline</v-icon>
            Dados do profissional.
            <v-spacer></v-spacer>
            <v-btn
              outlined
              :to="{ name: 'usersCreate', params: {} }"
              class="mr-3"
            >
              <v-icon left>mdi-calendar-plus</v-icon>
              Tempo de pausa
            </v-btn>
            <v-btn outlined :to="{ name: 'bmEdit', params: { id: bm.id } }">
              <v-icon left>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="3">
                <v-card flat class="rounded-0">
                  <input
                    v-show="false"
                    ref="inputUpload"
                    type="file"
                    v-on:change="previewImage"
                  />
                  <v-btn
                    color="purple"
                    absolute
                    bottom
                    right
                    fab
                    outlined
                    x-small
                    @click="$refs.inputUpload.click()"
                  >
                    <v-icon>mdi-image-edit</v-icon>
                  </v-btn>
                  <v-img v-bind:src="bm.image">
                    <v-card-title
                      class="align-end text-right fill-height"
                      primary-title
                    >
                      <v-spacer></v-spacer>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-col>
              <v-col md="4" sm="6" xs="12"> <b>Nome:</b> {{ bm.name }} </v-col>
              <v-col md="4" sm="6" xs="12">
                <b>Documento:</b> {{ bm.document }}
              </v-col>
              <v-col md="4" sm="6" xs="12">
                <b>Tempo:</b> {{ bm.serviceStepTime }} min.
              </v-col>
              <v-col md="4" sm="6" xs="12">
                <b>Comissão sobre serviço:</b>
                {{ bm.commissionService }} %
              </v-col>
              <v-col md="4" sm="6" xs="12">
                <b>Comissão sobre Produto:</b>
                {{ bm.commissionService }} %
              </v-col>
              <v-col md="4" sm="6" xs="12">
                <b>Comissão sobre Insumo:</b>
                {{ bm.commissionService }} %
              </v-col>

              <v-col md="4" sm="12" xs="12">
                <b>Observação:</b> {{ bm.obs }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" xs="12">
                <h3>Serviços</h3>
              </v-col>
              <v-col
                md="3"
                sm="12"
                v-for="service in bm.services"
                :key="service.id + 'work'"
              >
                <v-card class="mx-auto" tile>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-hair-dryer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="service.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-text-long</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="service.desc"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ service.duration }}
                        min.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <h3>Dias de trabalho</h3>
              </v-col>
              <v-col
                md="3"
                sm="12"
                v-for="weekDay in bm.weekDays"
                :key="weekDay.id + 'off'"
              >
                <v-card class="mx-auto" tile>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-today</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="weekDay.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clock-in</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ weekDay.startTime }}
                        hrs.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clock-out</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ weekDay.endTime }}
                        hrs.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col sm="12">
                <h3>Folga.</h3>
              </v-col>
              <v-col
                md="6"
                sm="12"
                v-for="dayoff in bm.dayOffs"
                :key="dayoff.id"
              >
                <v-card class="mx-auto" tile>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clock-in</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Início
                        {{ dayoff.startDate | formatDate2 }}
                        hrs.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clock-out</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Fim
                        {{ dayoff.endDate | formatDate2 }}
                        hrs.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-alert type="warning" outlined v-show="!bm"
            >Nenhum usuário encontrado.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import {
  BM_DAYS_OFF_GET,
  BM_GET
  // BM_EDIT,
  // BM_CREATE,
  // BM_SEARCH,
} from "@/store/actions/bm.type";
import { BM_RESET_STATE, SET_BM_IMAGE } from "@/store/mutations/bm.type";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import { BMService } from "@/common/api/bm.service";
// import {SET_LOGO} from "@/store/mutations/profile.type";

export default {
  name: "bm",
  directives: { mask },
  props: {
    id: {
      default: 0,
      type: Number,
      required: true
    }
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(BM_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      await store.dispatch(BM_RESET_STATE),
      await store.dispatch(BM_GET, to.params.id),
      await store.dispatch(BM_DAYS_OFF_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    //Lista de Usuários
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    formIsValid: false
  }),
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    buscar() {
      // store.dispatch(USER_SEARCH);
      // console.log("buscar");
    },
    //Fim Methods Lista Usuarios

    reset() {
      this.$refs.formUsuario.reset();
    },
    async previewImage(img) {
      if (img.target.files[0]) {
        store.commit(CHANGE_PROGRESS, true);
        await BMService.save_image(this.bm.id, img.target.files[0])
          .then(() => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";
          })
          .catch(({ response }) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar Beauty Maker.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar Beauty Maker: " + response.data.message;
            }
          });
        store.commit(SET_BM_IMAGE, URL.createObjectURL(img.target.files[0]));
      }
    }
    // usuarioSubmit(id) {
    //   if (this.$refs.formUsuario.validate()) {
    //     //this.subject.image = this.subject.image
    //
    //     let action = id ? USER_EDIT : USER_CREATE;
    //     this.inProgress = true;
    //     this.bloqueiaEnvio = true;
    //     this.$store
    //       .dispatch(action)
    //       .then(() => {
    //         this.inProgress = false;
    //         this.bloqueiaEnvio = false;
    //         // this.$router.push({
    //         //   name: "usuario",
    //         //   params: { id: data.id }
    //         // });
    //         this.$refs.formUsuario.reset();
    //         this.snackbar = true;
    //         this.color = "success";
    //         this.text = "Salvo com sucesso";
    //       })
    //       .catch(({ response }) => {
    //         this.inProgress = false;
    //         this.bloqueiaEnvio = false;
    //         this.snackbar = true;
    //         this.color = "error";
    //         this.text = "Erro ao salvar usuário.";
    //         // console.log(response.data);
    //         if (typeof response.data.error !== "undefined") {
    //           this.text = response.data.error;
    //         }
    //       });
    //   } else {
    //     this.snackbar = true;
    //     this.color = "error";
    //     this.text = "Preencha o formulário corretamente";
    //   }
    // },
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["checkUsers", "bm", "snackbar"])
  },
  watch: {}
};
</script>
