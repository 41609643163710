import ApiService from "@/common/api/api.service";

export const BMService = {
    list() {
        ApiService.setHeader()
        return ApiService.get('bm')
    },
    busca(slug) {
        ApiService.setHeader()
        return ApiService.get('bm', slug)
    },
    get(slug) {
        ApiService.setHeader()
        return ApiService.get('bm', slug)
    },
    getDayOffs(slug) {
        ApiService.setHeader()
        slug = slug + "/days-off"
        return ApiService.get('bm', slug)
    },
    create(data) {
        ApiService.setHeader()
        return ApiService.post('bm', data)
    },
    update(slug, data) {
        ApiService.setHeader()
        return ApiService.update('bm', slug, data)
    },
    save_image(id, file){
        ApiService.setHeader()
        let formData = new FormData();
        formData.append("file", file);
        return ApiService.postFile('bm/'+id+'/image', formData)
    },
    delete(slug) {
        ApiService.setHeader()
        return ApiService.delete(`bm/${slug}`)
    }
}